import React from "react"
import { graphql, PageProps } from "gatsby"
import Template from "@app/components/Sections/Sections"

export type Props = PageProps<GatsbyTypes.TemplateFlexibleQuery, GatsbyTypes.TemplateFlexibleQueryVariables>

export const query = graphql`
  query TemplateFlexible($id: String!) {
    page: sanityPageFlexible(_id: { eq: $id }) {
      title
      handle {
        current
      }
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
